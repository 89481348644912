<template>
  <div
    class="drawer"
    :style="{
      transform: `${!value ? 'translateX(485px)' : 'translateX(0)'}`,
      opacity: `${!value ? '0' : '1'}`
    }"
  >
    <i class="el-icon-close" @click="$emit('input', false)"></i>

    <div class="drawer-info _flex">
      <div class="drawer-info__img-box">
        <img :src="detail.imgUrl" />
        <el-button
          type="info"
          @click="
            () => {
              $store.commit('SET_TOWER_CODE', detail.code)
              $router.push({
                path: '/dashboard',
                query: {
                  code: detail.code
                }
              })
            }
          "
          v-if="[1, 2].includes(detail.catagories)"
          >View dashboard</el-button
        >
      </div>

      <div class="info-right _font-weight__600">
        <div class="info-right__name _font-size__heading3">{{ detail.name }}</div>
        <div
          class="info-right__item _font-size__sub__heading"
          v-for="item in keys"
          :key="item.label"
        >
          <span>{{ item.label }}</span>
          <span>{{
            item.formatter && detail[item.key] ? item.formatter(detail[item.key]) : detail[item.key]
          }}</span>
        </div>
        <div class="info-right__item _flex">
          <span>Categories</span>
          <div class="item-catagories _font-weight__600">
            <div class="_flex _flex-align__center" v-if="[0, 2].includes(detail.catagories)">
              <img :src="Inspected" alt="" />
              <span>Inspection</span>
            </div>
            <div class="_flex _flex-align__center" v-if="[1, 2].includes(detail.catagories)">
              <img :src="Monitoring" alt="" />
              <span>Sensor Monitoring</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="drawer-label _font-size__heading3 _font-weight__600">Inspection Report</div>

    <div class="drawer-table">
      <div class="drawer-table__row _font-size__sub__heading _font-weight__600">
        <div>Report Date</div>
      </div>
      <el-scrollbar class="drawer-table__scrollbar" :style="{ height: tableHeight + 'px' }">
        <!-- v-for="(row, index) in list" -->
        <!-- :key="index" -->
        <div
          class="drawer-table__row _flex _flex-align__center _flex-justify__between _font-size__sub__heading"
        >
          <div>
            {{
              detail.reportDateStr ||
              (detail.reportFileName && detail.reportFileName.match(/[\w\.]+/)[0])
            }}
          </div>
          <el-button type="info" @click="handleViewRecord(detail)"> View record </el-button>
        </div>
      </el-scrollbar>
    </div>

    <!-- <div class="delete-project _flex">
      <el-button>Delete Project</el-button>
    </div> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import Monitoring from '@/assets/icons/home/Monitoring.png'
// eslint-disable-next-line import/no-unresolved
import Inspected from '@/assets/icons/home/Inspected.png'
import { parseTime, formatTime } from '@/utils'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      Monitoring,
      Inspected,
      tableHeight: 0,
      isDrawer: false,
      keys: [
        {
          label: 'Tower ID',
          key: 'code'
        },
        {
          label: 'Location',
          key: 'location'
        },
        {
          label: 'Created',
          key: 'createdTime',
          formatter: (val) => `${parseTime(val, 'reg')}`
        },
        {
          label: 'Last Modified',
          key: 'updateTime',
          formatter: (val) => `${formatTime(Number(val))}`
        }
      ],
      list: [
        {
          date: '19 Mar 2023'
        },
        {
          date: '19 Mar 2023'
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      const dom = document.getElementsByClassName('drawer-table__scrollbar')
      if (dom.length) {
        // 30为底部空白 (+ || -)实际情况所偏差
        this.tableHeight = window.innerHeight - dom[0].getBoundingClientRect().top - 65
      }
    })
  },
  methods: {
    handleViewRecord(detail) {
      this.$emit('input', false)
      this.$bus.$emit('tower-detail', detail)
      this.$store.commit('SET_TOWER_CODE', detail.code)
      this.$router.push({
        path: '/analysis',
        query: {
          tabsIndex: 0,
          catagories: detail.catagories,
          code: detail.code
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  height: calc(100vh - 55px);
  width: 485px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease;
  background: var(--color-white);

  .el-icon-close {
    position: absolute;
    top: 30px;
    right: 30px;
    font-weight: 600;
    font-size: 20px;
  }

  &-info {
    padding: 30px;

    &__img-box {
      margin-right: 20px;
      width: 155px;
      img {
        width: 100%;
        height: 155px;
        border-radius: 10px;
        object-fit: cover;
        margin-bottom: 22px;
      }
    }

    .info-right {
      flex: 1;
      &__name {
        margin-bottom: 20px;
      }

      &__item {
        margin-bottom: 20px;
        font-size: 12px;
        > span:first-of-type {
          display: inline-block;
          width: 90px;
          margin-right: 20px;
        }

        .item-catagories {
          > div + div {
            margin-top: 15px;
          }
          > div {
            font-size: 12px;
            img {
              width: 25px;
              height: 25px;
              margin-right: 10px;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &-label {
    margin-bottom: 5px;
    padding: 20px 25px;
  }

  &-table {
    &__row {
      border-bottom: 1px solid var(--color-info-light-5);
      padding: 10px 30px;
      height: 50px;
      line-height: 30px;
      transition: background 0.3s;
    }

    .el-scrollbar .drawer-table__row:hover {
      background: var(--color-primary-light-9);
    }
  }

  .delete-project {
    width: 100%;
    height: 65px;
    padding: 12px 30px;
    justify-content: end;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--color-info);

    & .el-button {
      border-color: var(--color-info);
      &:hover,
      &:focus {
        border-color: var(--color-primary);
      }
    }
  }
}
</style>
